import React from 'react';
import styles from "./f.module.scss"

export default function PopupFooter(props) {

  return <div className={styles.footer + (props.className ? ' ' + props.className : '')}>
    { props.children }
  </div>

}
