'use client'

import { useEffect, useRef, useState } from "react"
import ApiUtils from "@/utils/ApiUtils"
import { formatCurrency, formatNumber } from "@/plugins/Formatters"
import { ADD_FILLED, ADD_SQUARE_FILLED, APPEARANCE, ARROW, ARROW_RIGHT, BROCHURE, CHECK, CLIPBOARD, COMMENT_INFO, CUSTOMIZE, DENT, HAZARD, INFO_CIRCLE, INFO_I, KEY, MAINT, OEM_LOGOS, PAYMENT_PLAN, PENCIL, PLAY, PLUS, POPULAR, POP_OUT, PRINT, PROTECT, QUOTE, RECOMMEND, SERVICE, SHIELD_CHECK, TICK_FILLED, TIRE, TRASH, VIDEO, WARRANTY, WINDSHIELD, X, oemLogo } from "@/components/Icons"
import useEventListener, { emitEvent } from "@/plugins/EventListener"
import Tabs from "@/components/tabs/Tabs"
import Image from "next/image"
import { CATEGORY_MAP, planPrice, replacePlaceholders } from "@/utils/Helpers"
import Popup from "@/components/popup/Popup"
import PopupBody from "@/components/popup/PopupBody"
import { useRouter } from "next/navigation"
import GlobalVideoPlayer from "./partials/GlobalVideoPlayer"
import PopupFooter from "@/components/popup/PopupFooter"
import { useChatEventListener } from "@ginieapp/chat-plugin"
import HelpInfoSection from "./partials/HelpInfoSection"
import Footer from "./partials/Footer"
import Page from "./Page"
import VehicleForm from "./partials/VehicleForm"
import Form from "@/components/form/Form"
import ContactFormPopup from "./partials/ContactFormPopup"
import { isMobile } from "react-device-detect"


const categoryProps = CATEGORY_MAP

const bullets = {
  vsc: [
    "Covers repairs to major components like engine, transmission, etc. once the manufacturer's warranty ends.",
    "Protect against costly repairs."
  ]
}

export const bundleIdRecommended = "recommended"
export const bundleIdPopular = "popular"
export const bundleIdCustom = "custom"

const bundleInfo = {
  [bundleIdRecommended]: { subtext: "Protection Packages", icon: RECOMMEND },
  [bundleIdPopular]: { subtext: "Protection Packages", icon: POPULAR },
  [bundleIdCustom]: { subtext: "Customize and Choose", icon: CUSTOMIZE },
}

export default function ProductsScreen(props) {

  const router = useRouter()

  const page = useRef()
  const vehiclePopup = useRef()
  const contactPopup = useRef()

  const vehicle = props.vehicle
  const config = props.config
  const legal = props.config.legal
  const prodConfig = config.products
  const paymentPlans = config.payment.payment_plan?.length > 0

  let bundles = prodConfig.bundles?.length > 0 ? prodConfig.bundles : [
    { name: "Build Your Own", bundle_type: bundleIdCustom, criteria: prodConfig.categories }
  ]
  if (vehicle.params?.qa) {
    if (!bundles.map(e => e.bundle_type).includes(bundleIdRecommended)) {
      bundles.push({ name: "Recommended", bundle_type: bundleIdRecommended, criteria: prodConfig.categories })
    }
  }
  if (!bundles.map(e => e.bundle_type).includes(bundleIdCustom)) {
    bundles.push({ name: "Build Your Own", bundle_type: bundleIdCustom, criteria: prodConfig.categories })
  }

  const steps = bundles.map(e => { return { key: e.bundle_type, label: e.name } })
  const metadata = bundles.reduce((map,b1) => {
    map[b1.bundle_type] = Object.assign({}, b1, bundleInfo[b1.bundle_type])
    return map
  }, {})

  const clientProductsEnabled = prodConfig.enabled || Object.keys(categoryProps).map(e => { return { category: e } })
  const productsEnabledByMake = clientProductsEnabled.filter(p => {
    if (p.brands?.includes(vehicle.make_name)) return true
  })
  const productsEnabled = productsEnabledByMake.length > 0 ? productsEnabledByMake : clientProductsEnabled.filter(p => {
    return !p.brands || p.brands.length == 0
  })

  const pCatConfig = config.productCategories.reduce((map,pc) => {
    map[pc.category] = pc
    return map
  }, {})

  const categories = prodConfig.categories.map((e,index) => { return Object.assign(e, { id: e.category, name: e.display_name, index: index }) })
  const categoryConfigs = {}
  categories.map(e => { 
    const pd = pCatConfig[e.id] || {}
    e.color = e.color || pd.color
    e.icon = e.icon || pd.icon
    e.bgImage = e.image || pd.image
    e.defaultIcon = categoryProps[e.id].icon
    e.bullets = e.bullets || pd.bullets
    categoryConfigs[e.id] = e
  })

  const placeholderPlans = {}
  categories.map(c => {
    placeholderPlans[c.id] = []
  })

  const planNames = {}
  prodConfig.names?.map(v => {
    planNames[v.name] = v
  })

  const customizePopup = useRef()
  const surchargesPopup = useRef()
  const infoPopup = useRef()
  const videoPanel = useRef()
  const videoPlayer = useRef()

  const passedBundle = props.searchParams.b ? bundles.filter(e => e.bundle_type == props.searchParams.b)[0] : null

  const [selectedBundle,setSelectedBundle] = useState(props.inputs && Object.keys(props.inputs).length > 0 ? bundleIdCustom : passedBundle ? passedBundle.bundle_type : bundles[0].bundle_type)
  const [plans,setPlans] = useState(placeholderPlans)
  const [loading,setLoading] = useState(true)
  const [cart,setCart] = useState()
  const [customizeOptions,setCustomizeOptions] = useState([])
  const [allCustomizeOptions,setAllCustomizeOptions] = useState()
  const [selectionOptions,setSelectionOptions] = useState()
  const [selectFilters,setSelectFilters] = useState({})
  
  const [list,setList] = useState([])
  const [custom,setCustom] = useState([])
  const [customNames,setCustomNames] = useState([])
  const [recommended,setRecommended] = useState([])
  const [popular,setPopular] = useState([])
  const [expandedOptionIndex,setExpandedOptionIndex] = useState(-1)
  const [processing,setProcessing] = useState()
  const [productInfo,setProductInfo] = useState()
  const [expandedInfoIndex,setExpandedInfoIndex] = useState("-1")
  const [video,setVideo] = useState()
  const [bundleProductInfo, setBundleProductInfo] = useState([])
  const [planSurcharges,setPlanSurcharges] = useState()
  const [plansWithSurcharge,setPlansWithSurcharge] = useState([])
  const [planSurchargesIndex,setPlanSurchargesIndex] = useState(0)
  const [planSurchargesSalt,setPlanSurchargeSale] = useState(1)
  const [surchargesPresented,setSurchargesPresented] = useState(false)
  const [promoCode,setPromoCode] = useState(vehicle.promo_code)
  const [listLoaded,setListLoaded] = useState(1)
  const [cartError,setCartError] = useState({})

  let placeholderParams = {
    vehicle: vehicle,
    config: config
  }

  useEventListener("add_product", event => {
    let p = event.detail.item
    p.cat_icon = categoryConfigs[p.category].icon
    p.cat_name = categoryConfigs[p.category].name
    if (event.detail.promo) {
      p.sale_price = (p.original_price || p.price) - event.detail.promo.discount
      p.promo_code = event.detail.promo.code
    }
    onSelect(null, event.detail.item)
  })

  useChatEventListener("chat-message", event => {
    const message = event.detail
    if (message.attachment?.event_type == "fni-plan") {
      setTimeout(() => {
        load(cart, {}, true)
      }, 1000)
    }
    if (message.type == "promo") {
      setTimeout(() => {
        load(cart, {}, true)
      }, 1000)
    }
  })

  useEventListener("vehicle_updated", event => {
    load(cart, props.inputs, true)
  })

  useEffect(() => {
    if (vehicle.visitor_id) {
      ApiUtils.checkVisitorId(vehicle.visitor_id)
    }
    ApiUtils.getRequest("/v1/carts?vehicle_id=" + vehicle.vehicle_id)
    .then(data => {
      if (data.cart?.bundle?.bundle_id) {
        setSelectedBundle(data.cart.bundle?.bundle_id)
      }
      setCart(data.cart)
      if (data.cart) {
        load(data.cart)
        if (data.cart.customer) {
          placeholderParams.customer = data.cart.customer
        }
      } else {
        load(null, props.inputs)
      }
    })
  }, [])

  // useEffect(() => {
  //   if (cart && list.length > 0) {
  //     let data = [...list]
  //     let productIds = cart.items.map(e => e.category)
  //     data.map(item => {
  //       item.removed = !productIds.includes(item.category)
  //     })
  //   }
  // }, [list,cart])

  useEffect(() => {
    if (list.length > 0) addToCart(null, 'temp')
  }, [listLoaded, selectedBundle])

  const load = async (cart, inputs, refresh) => {
    if (!refresh) setLoading(true)
    Promise.all(productsEnabled.map(prodConfig =>
      ApiUtils.getRequest("/v1/products", { partner_id: prodConfig.partner_id, product_id: prodConfig.product_id || prodConfig.category, vehicle_id: vehicle.vehicle_id, term: 36}).then(resp => resp)
    )).then(responses => {
      let data = {}
      let recs = []
      let productsMap = {}
      let productCategories = []
      cart?.items?.map(item => {
        productsMap[item.product_id] = item
        productCategories.push(item.category)
      })
      responses.map(results => {
        const allPlans = results.plans?.sort((a,b) => {
          return (a.term && a.term > b.term ? 1 : 0) || (a.miles && a.miles > b.miles ? 1 : 0) || ((b.deductible?.amount || 0) - (a.deductible?.amount || 0))
        })
        results.plans?.map(p => {
          p.category = p.category.toLowerCase()
          if (productsMap[p.product_id]) {
            p.surcharges = productsMap[p.product_id].surcharges
          }
          let list = data[p.category]
          if (!list) { 
            list = []
            data[p.category] = list
          }
          p.cat_icon = categoryConfigs[p.category].icon
          p.cat_name = categoryConfigs[p.category].name
          list.push(p)
          if (!recs.filter(e => e.category == p.category)[0]) {
            recs.push(p)
          }
        })
      })
      const recBundle = bundles?.filter(e => e.bundle_type == bundleIdRecommended)[0]
      const cartBundle = cart?.bundle?.bundle_id || selectedBundle
      if (recBundle?.criteria?.length > 0) {
        let recs = buildBundle(cart, vehicle.params?.qa || recBundle?.criteria, data)
        setRecommended(recs)
        if (cartBundle == bundleIdRecommended) setList(recs)
      }
      const popuBundle = bundles?.filter(e => e.bundle_type == bundleIdPopular)[0]
      if (popuBundle?.criteria?.length > 0) {
        let recs = buildBundle(cart, popuBundle?.criteria, data)
        setPopular(recs)
        if (cartBundle == bundleIdPopular) setList(recs)
      }
      const customBundle = bundles?.filter(e => e.bundle_type == bundleIdCustom)[0]
      if (inputs && Object.keys(inputs).length > 0) {
        let recs = buildCustom(cart, inputs.rules, data)
        setCustom(recs)
        if (cartBundle == bundleIdCustom) setList(recs)
      } else if (customBundle?.criteria?.length > 0) {
        let recs = buildBundle(cart, customBundle?.criteria, data)
        setCustom(recs)
        if (cartBundle == bundleIdCustom) setList(recs)
      }
      setPlans(data)
      setLoading(false)
      setListLoaded(new Date().getTime())
    })
  }

  const buildBundle = (cart, rules, data) => {
    let recs = []
    rules.map(rule => {
      if (data[rule.category]) {
        let products = data[rule.category]
        let terms = rule.rules?.term?.split(",").map(e => parseInt(e))
        let miles = rule.rules?.miles?.split(",").map(e => parseInt(e))
        if (rule.rules?.name) {
          products = products.sort((a,b) => {
            const regex = RegExp(rule.rules.name, "i")
            if (regex.test(a.name) && regex.test(b.name)) {
              return 0
            } else if (regex.test(a.name)) {
              return -1
            } else if (regex.test(b.name)) {
              return 1
            } else {
              return -1
            }
          })
        }
        let cartProduct = null
        if (cart && cart.items.length > 0) {
          cart.items.map(item => {
            if (item.category == rule.category) {
              cartProduct = item
            }
          })
        }
        let prevProduct = null
        if (cartProduct) {
          prevProduct = products.filter(e => e.product_id == cartProduct.product_id)[0]
        }
        if (prevProduct) {
          prevProduct.price = cartProduct.price
          prevProduct.original_price = cartProduct.original_price
          prevProduct.promo_code = cartProduct.promo_code
          recs.push(prevProduct)
        } else {
          let sorted = products.sort((a,b) => {
            return (terms ? terms.indexOf(b.term) - terms.indexOf(a.term) : 0) || (miles ? miles.indexOf(b.miles) - miles.indexOf(a.miles) : 0)
          })
          recs.push(sorted[0])
        }
      }
    })
    return recs
  }

  const onBundleSelection = (tab) => {
    setSelectedBundle(tab.key)
    if (tab.key == bundleIdRecommended) setList(recommended)
    if (tab.key == bundleIdPopular) setList(popular)
    if (tab.key == bundleIdCustom) setList(custom)
    setListLoaded(new Date().getTime())
  }

  const addToCart = (e, status) => {
    if (e) e.preventDefault()
    const selectedPlans = list.filter(e => !e.removed)
    let planWithSurcharges = []
    selectedPlans.map(p => {
      if (p.surcharges?.length > 0) {
        planWithSurcharges.push(p)
      }
    })
    if (status != 'temp' && !surchargesPresented && planWithSurcharges.length > 0) {
      setPlansWithSurcharge(planWithSurcharges)
      setPlanSurchargesIndex(0)
      setPlanSurcharges(planWithSurcharges[0])
      surchargesPopup.current.show()
      setSurchargesPresented(true)
      return
    }
    if (status == "pending") setProcessing("add")
    const payload = {
      vehicle_id: vehicle.vehicle_id,
      products: selectedPlans,
      status: status,
      bundle: selectedBundle,
      bundle_name: metadata[selectedBundle].name,
      host: config.host,
      promo_code: promoCode
    }
    ApiUtils.postRequest("/v1/carts/add", payload)
    .then(data => {
      setCart(data.cart)
      setCartError(data.error)
      if (status == 'pending') {
        setProcessing(null)
        emitEvent("cart_update", data.cart)
        if (data.cart.customer || isMobile) {
          router.push("/checkout/" + data.cart.cart_id)
        } else {
          contactPopup.current.show()
        }
      } else {
        setProcessing(null)
      }
      syncCartItems(data.cart)
    })
    .catch(e => {
      setProcessing(null)
    })
  }

  const customize = (e, category) => {
    e.preventDefault()
    setProductInfo(null)
    setExpandedOptionIndex(-1)
    setExpandedInfoIndex("-1")
    customizePopup.current.show()
    const customOptions = buildCustomizeOptions(plans[category])
    setSelectFilters({})
    setCustomizeOptions(customOptions.list)
    setAllCustomizeOptions(JSON.parse(JSON.stringify(customOptions.list)))
    setSelectionOptions(customOptions.options)
  }

  const buildCustomizeOptions = (plans) => {
    let listByName = {}
    plans.map(plan => {
      const name = planNames[plan.name] ? planNames[plan.name].display : plan.name
      if (!listByName[name]) {
        listByName[name] = []
      }
      listByName[name].push(plan)
    })
    let sortedNames = Object.keys(listByName).sort((a,b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
    let list = []
    let selectOptions = { terms: [], miles: [], deductibles: [] }
    sortedNames.map(name => {
      let productIds = []
      let options = []
      let priceRange = { min: 99999, max: 0 }
      let serviceIntervals = []
      listByName[name].map(opt => {
        if (opt.service_interval && serviceIntervals.indexOf(opt.service_interval) < 0) serviceIntervals.push(opt.service_interval)
      })
      listByName[name].map(opt => {
        let optLabel = serviceIntervals.length > 0 ? "Interval(" + opt.service_interval + ") - " : ""
        if (opt.deductible?.description) {
          optLabel = optLabel +  opt.deductible?.description
        }
        opt.option_label = optLabel
        if (optLabel && options.indexOf(optLabel) < 0) {
          options.push(optLabel)
        }
        if (opt.price < priceRange.min) priceRange.min = opt.price
        if (opt.price > priceRange.max) priceRange.max = opt.price
        productIds.push({ product_id: opt.product_id })
      })
      options = options.sort((a,b) => a.localeCompare(b))

      let planOptions = listByName[name]
      if (options.length > 1) {
        let planGroups = {}
        listByName[name].map(p => {
          const keys = [p.name]
          if (p.term) keys.push(p.term)
          if (p.miles) keys.push(p.miles)
          if (!planGroups[keys.join("--")]) {
            planGroups[keys.join("--")] = Object.assign({}, p, {options: []})
          }
          planGroups[keys.join("--")].options.push(p)
          if (p.term && !selectOptions.terms.includes(p.term)) selectOptions.terms.push(p.term)
          if (p.miles && !selectOptions.miles.includes(p.miles)) selectOptions.miles.push(p.miles)
          if (p.deductible?.amount && !selectOptions.deductibles.includes(p.deductible?.amount)) selectOptions.deductibles.push(p.deductible?.amount)
        })
        planOptions = Object.values(planGroups).sort((a,b) => a.name.localeCompare(b.name))
      }

      list.push({
        name: name,
        range: priceRange,
        plans: planOptions,
        selected: selectedProductId(productIds)
      })
    })
    selectOptions.terms = selectOptions.terms.sort((a,b) => a - b)
    selectOptions.miles = selectOptions.miles.sort((a,b) => a - b)
    selectOptions.deductibles = selectOptions.deductibles.sort((a,b) => a - b)
    return { list: list, options: selectOptions }
  }

  const totalPrice = (discount) => {
    let total = 0
    list.map(e => {
      if (!e.removed) total += planPrice(e)
    })
    if (discount && cart?.discount > 0) total = total - cart.discount
    return total
  }

  const deductible = (p) => {
    return formatCurrency(p.deductible?.amount) + ' deductible'
  }

  const summaryText = (p, fields) => {
    let array = []
    fields.map(f => {
      if (f == 'price') {
        let priceStr = formatCurrency(planPrice(p))
        if (planPrice(p) == 0) {
          array.push("FREE")
        } else if (p.original_price && p.original_price != p.price) {
          array.push(priceStr + " <h5>" + formatCurrency(planPrice(p, true)) + "</h5>")
        } else {
          array.push(priceStr)
        }
      }
      if (f == 'term' && p.term) {
        if (p.term > 200) {
          array.push("Life-time")
        } else {
          array.push(p.term + ' months')
        }
      }
      if (f == 'miles' && p.miles) {
        if (p.miles > 500000) {
          array.push('Unlimited miles')
        } else {
          array.push(formatNumber(p.miles) + ' miles')
        }
      }
      if (f == 'deduc') {
        if (p.deductible?.amount > 0) {
          array.push(deductible(p))
        } else {
          array.push('$0 deductible')
        }
      }
      if (f == "interval") {
        if (p.service_interval) array.push(formatNumber(p.service_interval) + ' intervals')
      }
    })
    return array.join(", ")
  }

  const toggleOption = (e, index) => {
    setExpandedOptionIndex(expandedOptionIndex == index ? -1 : index)
    let actualNames = []
    customizeOptions[index].plans.map(plan => {
      if (!actualNames.includes(plan.name)) actualNames.push(plan.name)
    })
    setCustomNames(actualNames)
  }

  const onSelect = (e, item, selectOnly) => {
    if (e) e.preventDefault()
    let index = -1
    let data = [...custom]
    item.removed = false
    data.map((x,i) => {
      if (x.category == item.category) index = i
    })
    if (index >= 0) {
      data[index] = item
    } else {
      data.push(item)
    }
    setCustom(data)
    setList(data)
    setListLoaded(new Date().getTime())
    if (!selectOnly) {
      customizePopup.current.hide()
      setExpandedOptionIndex(-1)
      if (item.surcharges?.length > 0) {
        setPlanSurcharges(item)
        surchargesPopup.current.show()
      }
    }
  }

  const requestProductInfo = (e,plan) => {
    e.preventDefault()
    emitEvent("request_product_info", plan)
  }

  const togglePlanIncluded = (e, item) => {
    e.preventDefault()
    let index = -1
    let data = []
    if (selectedBundle == bundleIdRecommended) data = [...recommended]
    if (selectedBundle == bundleIdPopular) data = [...popular]
    if (selectedBundle == bundleIdCustom) data = [...custom]
    data.map((x,i) => {
      if (x.category == item.category) index = i
    })
    let message = data[index].removed ? 'Added' : 'Removed'
    data[index].removed = !data[index].removed
    if (selectedBundle == bundleIdCustom) {
      setCustom(data)
    }
    page.current.sendMessage( message + " " + item.name + " (" + formatCurrency(item.price) + ")")
    setList(data)
    setListLoaded(new Date().getTime())
  }

  const getInfo = (e, params, index, pIndex, plan) => {
    e.preventDefault()
    const expIdx = index + "-" + pIndex
    if (expIdx == expandedInfoIndex) {
      setExpandedInfoIndex("-1");
    } else {
      ApiUtils.getRequest("/v1/products/info", params)
      .then(data => {
        if (data.info) {
          setProductInfo(data.info)
          setExpandedInfoIndex(expIdx)
          if (plan) {
            emitEvent("request_product_info", { plan: plan, info: data.info })
          }
        }
      })
    }
  }

  const requestMoreInfo = (plan, info) => {
    emitEvent("request_product_info", { plan: plan, info: info })
    infoPopup.current.hide()
  }

  const showBundleInfo = (e) => {
    e.preventDefault()
    setBundleProductInfo([])
    Promise.all(list.map(plan =>
      ApiUtils.getRequest("/v1/products/info", { pid: plan.p_product_id, name: plan.name, info_id: planNames[plan.name]?.info_id, group: planNames[plan.name]?.display, category: plan.category }).then(resp => {
        plan.info = resp.info || {}
        return plan
      })
    )).then(responses => {
      setBundleProductInfo(responses)
      infoPopup.current.show()
    })
  }

  const showProductInfo = (e,plan) => {
    e.preventDefault()
    setBundleProductInfo([])
    ApiUtils.getRequest("/v1/products/info", { pid: plan.p_product_id, name: plan.name, info_id: planNames[plan.name]?.info_id, group: planNames[plan.name]?.display, category: plan.category }).then(resp => {
      plan.info = resp.info || {}
      return plan
    }).then(data => {
      setBundleProductInfo([data])
      infoPopup.current.show()
    })
  }

  const playVideo = (e, video) => {
    e.preventDefault()
    setVideo(video)
    videoPanel.current.show()
    if (videoPlayer?.current) {
      videoPlayer.current.play(video.yt_link)
    }
  }

  const stopVideo = (e) => {
    videoPlayer.current.stop()
    videoPanel.current.hide()
  }

  const onPromoCodeChange = (e) => {
    e.preventDefault()
    setPromoCode(e.target.value)
  }

  const applyPromoCode = (e) => {
    e.preventDefault()
    addToCart(null, 'temp')
  }

  const removePromoCode = (e) => {
    e.preventDefault()
    ApiUtils.postRequest("/v1/carts/" + cart.cart_id + "/update_cart", { remove_promo: true })
      .then(data => {
        setCart(data.cart)
        syncCartItems(data.cart)
      })
  }

  const syncCartItems = (data) => {
    let items = [...list]
    items.map(item => {
      const cartItem = data.items.filter(e => e.product_id == item.product_id)[0]
      item.original_price = cartItem.original_price
      item.price = cartItem.price
    })
    setList(items)
  }

  const onSelectFilterChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const filters = Object.assign({}, selectFilters, {[name]: value})
    setSelectFilters(filters)
    const filteredList = allCustomizeOptions.map(p => {
      let plans = []
      p.plans.map(e => {
        let b = true
        b = b && (!filters.term || filters.term == e.term)
        b = b && (!filters.miles || filters.miles == e.miles)
        b = b && ((!filters.deductible && filters.deductible != "0") || (e.options?.filter(x => x.deductible?.amount == filters.deductible)))
        if (b) {
          if (filters.deductible || filters.deductible == "0") {
            e = e.options?.filter(x => x.deductible?.amount == filters.deductible)[0]
            if (e) e.options = [Object.assign({},e)]
          }
          if (e) plans.push(e)
        }
      })
      return Object.assign({}, p, { plans: plans })
    })
    setCustomizeOptions(filteredList)
  }

  const renderProductInfo = (plan, info) => {
    if (!info.bullets && !info.brochure && !info.video) return <></>
    return <><div className="product-desc">
      <div></div>
      <div className="product-desc-info">
        <div className="product-desc-bullets">
          { info.bullets?.map((b,i) => (
          <div key={i}>
            <div>{ SHIELD_CHECK }</div>
            <div>{ b }</div>
          </div>
          ))}
        </div>
        <div className="product-desc-brochure">
          { info.brochure &&
          <a className="button button-line button-inline" href={info.brochure} target="_brochure"><span>More...</span> <i>{ POP_OUT }</i></a>
          }
          { info.faq &&
          <a className="button button-line button-inline" href={info.faq} target="_brochure"><span>FAQ</span> <i>{ POP_OUT }</i></a>
          }
          <button className="button button-icon-line button-icon-sm" aria-label="Print" onClick={e => print(e, plan.product_id, { plan: plan, info: info })}>{ PRINT }</button>
        </div>
      </div>
      { info.video?.video_source &&
      <div className="product-desc-video" onClick={e => playVideo(e, info.video)}>
        <Image src={info.video.cover} alt={plan.name} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "cover" }} priority={false} sizes={'1600x1600'}></Image>
        <div className="product-items-video-play">{ PLAY }</div>
      </div>
      }
      <div></div>
    </div>
    <div className="product-desc-req">
      <button className="button button-primary" aria-label="Request More Information" onClick={e => requestMoreInfo(plan, info)}>Request More Information</button>
    </div>
    </>
  }

  const print = (e, salt, data) => {
    e.preventDefault()
    const key = props.params.slug + "-print-" + salt
    ApiUtils.postRequest("/v1/cache_data", { key: key, data: Object.assign({}, data, { vehicle: vehicle }) })
    .then(resp => {
      window.open("/products/details/" + key + "/print", "product-detail-popup", "left=100,top=100,width=1000,height=640")
    })
  }

  const onDeductiblePlanSelect = (e, groupIndex, planIndex) => {
    let productId = e.target.value
    let data = [...customizeOptions]
    const p = data[groupIndex].plans[planIndex].options.filter(e => e.product_id == productId)[0]
    data[groupIndex].plans[planIndex] = Object.assign(data[groupIndex].plans[planIndex], p)
    setCustomizeOptions(data)
    setAllCustomizeOptions(JSON.parse(JSON.stringify(data)))
    if (selectedProductId(data[groupIndex].plans[planIndex].options)) {
      onSelect(e,p,true)
    }
  }

  const onSurchargeToggle = (e) => {
    e.preventDefault()
    const checked = e.target.checked
    const code = e.target.value
    const surcharge = planSurcharges.surcharges.filter(e => e.code == code)[0]
    surcharge.selected = !surcharge.selected
    setPlanSurchargeSale(new Date().getTime())
  }

  const onSurchargeDone = (e) => {
    e.preventDefault()
    if (planSurchargesIndex + 1 == plansWithSurcharge.length) {
      surchargesPopup.current.hide()
      addToCart(e, "pending")
    } else {
      setPlanSurchargesIndex(planSurchargesIndex + 1)
      setPlanSurcharges(plansWithSurcharge[planSurchargesIndex + 1])
      surchargesPopup.current.hide()
    }
  }

  const selectedProductId = (plans) => {
    let id = null
    const ids = plans.map(e => e.product_id)
    list.map(e => {
      if (ids.includes(e.product_id)) id = e.product_id
    })
    return id
  }

  const updateVehicle = (e) => {
    e.preventDefault()
    vehiclePopup.current.show()
  }
  
  const onContactSubmit = () => {
    router.push("/checkout/" + cart.cart_id)
  }
  
  const gsub = (str) => {
    return replacePlaceholders(str, placeholderParams)
  }

  return <Page ref={page} {...props} cart={cart} config={config} chatPanel={true}>
    <div className="product-column">
      <section className="centered">
        <div dangerouslySetInnerHTML={{__html: gsub(prodConfig.texts?.header_line1 || "Protect your <b>[v_year] [v_model]</b>.") }}></div>
        { loading ?
        <div>Loading...</div>
        :
        <div dangerouslySetInnerHTML={{__html: gsub(prodConfig.texts?.header_line2 || "Here are our recommendations.") }}></div>
        }
      </section>
      { steps.length > 1 &&
      <section className="centered m-full">
        <Tabs key={cart?.cart_id || '1'} items={steps} selected={selectedBundle} onChange={tab => onBundleSelection(tab)}></Tabs>
      </section>
      }
      {/* { loading &&
      <section>
        <div style={{height:'200px'}}>
          <div className="processing-primary processing-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      </section>
      } */}
      <section>
        { loading &&
        <div className="product-tiles">
          <div className={"product-tile product-tile-2 loading-bg"}></div>
          <div className={"product-tile product-tile-2 loading-bg"}></div>
        </div>
        }
        { !loading &&
        <div className="product-tiles">
          { list.map((item,index) => (
            <div className={"product-tile product-tile-" + list.length} key={index}>
              <div className={"product-tile-info" + (item.removed ? " product-tile-deleted" : "")}>
                <div className={ "product-tile-top" + (item.removed ? " product-tile-top-deleted" : "")} style={{backgroundColor: categoryConfigs[item.category].color}}>
                  { categoryConfigs[item.category].bgImage &&
                  <Image src={categoryConfigs[item.category].bgImage} alt={item.name} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "cover" }} priority={true} sizes={'1600x1600'}></Image>
                  }
                  { categoryConfigs[item.category].icon ?
                  <div dangerouslySetInnerHTML={{__html: categoryConfigs[item.category].icon }}></div>
                  :
                  <div>{ categoryConfigs[item.category].defaultIcon }</div>
                  }
                  <div className="product-tile-top-p">
                    <span className={"product-tile-top-subtext"}>As low as</span>
                    <span className={"product-tile-top-price"}>{ formatCurrency(planPrice(item)*2/item.term) }</span>
                    <span className={"product-tile-top-subtext"}>per month</span>
                  </div>
                </div>
                <div className="product-tile-info-name">{ item.cat_name }</div>
                <div className="product-tile-info-terms" dangerouslySetInnerHTML={{__html: summaryText(item, ['term','miles','deduc','interval']) }}></div>
                <ul>
                  { (categoryConfigs[item.category]?.bullets || []).map((b,bi) => (
                  <li key={bi}><div>{ TICK_FILLED }</div><div>{b}</div></li>
                  ))}
                </ul>
              </div>
              <div className="product-tile-bottom">
                <div className={"product-tile-bottom-price" + (item.removed ? " product-tile-deleted" : "")}>
                  { planPrice(item) == 0 ?
                  <span>FREE</span>
                  :
                  <span>{ formatCurrency(planPrice(item)) } { planPrice(item,true) > planPrice(item) ? <span className="product-tile-bottom-discount">{ formatCurrency(planPrice(item,true)) }</span> : null }</span>
                  }
                  <span>{ "+ taxes & fees" }</span>
                </div>
                { list.filter(e => !e.removed || e.product_id == item.product_id).length > 1 &&
                <Form id={"remove_" + item.category} name={"Item Remove " + item.category} onSubmit={e => togglePlanIncluded(e,item)}>
                <button type="submit" className={"product-tile-bottom-button " + (item.removed ? ' product-tile-bottom-button-green' : ' product-tile-bottom-button-red')}
                  title={ item.removed ? "Add back" : "Remove" }
                  >{ item.removed ? ADD_SQUARE_FILLED : TRASH }</button>
                </Form>
                }
                <Form id={"info_" + item.category} button={true} name={"Item Info " + item.category} onSubmit={e => showProductInfo(e,item)}>
                <button type="submit" className="product-tile-bottom-button" title={ "More Information" }>{ INFO_CIRCLE }</button>
                </Form>
                { selectedBundle == bundleIdCustom && plans[item.category].length > 1 && !item.removed &&
                <Form id={"customize_" + item.category} button={true} name={"Item Customize " + item.category} onSubmit={e => customize(e,item.category)}>
                <button type="submit" className="product-tile-bottom-button" title={ "Customize Product" }>{ PENCIL }</button>
                </Form>
                }
              </div>
            </div>
          ))}
        </div>
        }
      </section>
      { !loading && paymentPlans &&
        <section className="centered">
          <span>{ PAYMENT_PLAN } Monthly payment plans are available at checkout.</span>
        </section>
      }
      <section className="max-f-xs">
        <div className={"product-bundle" + (loading ? ' loading-bg' : '')}>
          { !loading &&
          <>
          <div className="product-bundle-name">
            <span>{ "Total" }</span>
          </div>
          <div className="product-bundle-dark">
            <div className="product-bundle-price">
              <h2>{ formatCurrency(totalPrice(true))} { cart?.discount > 0 && <span className="text-line-thru">{ formatCurrency(totalPrice()) }</span> }</h2>
              <span>(+ taxes & fees)</span>
            </div>
            <div className="product-bundle-info">
              <button title="More Information" onClick={e => showBundleInfo(e)}>
                { INFO_CIRCLE }
              </button>
            </div>
          </div>
          </>
          }
        </div>
      </section>
      <section>
        <div className={"product-bundle min-f-xs" + (loading ? ' loading-bg' : '')}>
          { !loading &&
          <>
          <div className="product-bundle-icon">{ metadata[selectedBundle].icon }</div>
          <div className="product-bundle-name">
            <h2>{ metadata[selectedBundle].name }</h2>
            <span>{ metadata[selectedBundle].subtext }</span>
          </div>
          <div className="product-bundle-dark">
            <div className="product-bundle-price">
              <h2>{ formatCurrency(totalPrice(true))} { cart?.discount > 0 && <span className="text-line-thru">{ formatCurrency(totalPrice()) }</span> }</h2>
              <span> (+ taxes & fees)</span>
            </div>
            <div className="product-bundle-info">
              <button title="More Information" onClick={e => showBundleInfo(e)}>
                { INFO_CIRCLE }
              </button>
            </div>
          </div>
          </>
          }
        </div>
      </section>
      { !loading &&
      <section className="centered">
        <div className="flex-row-column flex-gapped flex-align-center">
          <div className="flex-col">
            { vehicle.vin ?
            <Form id="select_package_form" name="Select Package Form" onSubmit={e => addToCart(e, "pending")}>
              <button type="submit" className={"button button-inline " + (processing ? "processing" : "")} style={{ width: '200px' }} title="Select This Package">
                <span dangerouslySetInnerHTML={{__html: gsub(prodConfig.texts?.select_button || "Select This Package") }}></span>
                { processing &&
                <div className={'processing-ring'}><div></div><div></div><div></div><div></div></div>
                }
              </button>
            </Form>
            :
            <button className={"button button-inline " + (processing ? "processing" : "")} style={{ width: '200px' }} title="Update Vehicle" onClick={e => updateVehicle(e)}>
              <span dangerouslySetInnerHTML={{__html: "Update Vehicle" }}></span>
              { processing &&
              <div className={'processing-ring'}><div></div><div></div><div></div><div></div></div>
              }
            </button>
            }
          </div>
          <div className="flex-col flex flex-gapped flex-align-center">
            <div className="flex-col">
              <Form id="print_package" button={true} name="Print Package Button" onSubmit={e => print(e, list.map(e => e.product_id).join("."), { bundle: list, planNames: planNames })}>
              <button type="submit" className={"button button-icon-line"} title="Print">
                { PRINT }
              </button>
              </Form>
            </div>
            <div className="flex-col">
              <Form id="bundle_info" button={true} name="Show Bundle Info Button" onSubmit={e => showBundleInfo(e)}>
              <button type="submit" className={"button button-icon-line"} title="More Information" onClick={e => showBundleInfo(e)}>
                { INFO_CIRCLE }
              </button>
              </Form>
            </div>
          </div>
        </div>
      </section>
      }
      { cart?.promo ?
      <section className="centered bg-primary">
        <form id="remove_promo_code" name="Remove Promo Code Form" className="form" onSubmit={e => removePromoCode(e)}>
          <div className="flex-row-column flex-gapped flex-align-middle">
            <div className="flex-col">Promo Code Applied - <b>{ cart.promo.code }</b></div>
            <div className="flex-stretch min-b-x"></div>
            <div className="flex-col">{ cart.promo.description }</div>
            <div className="flex-col">
              <button className="button button-line-reverse" aria-label="Remove" style={{height:'32px'}}>Remove</button>
            </div>
          </div>
        </form>
      </section>
      :
      <section className="centered bg">
        <form id="promo_code" name="Promo Code Form" className="form" onSubmit={e => applyPromoCode(e)}>
          <div className="flex-row-column flex-gapped flex-align-middle">
            { cartError.promo ?
            <div className="flex-col text-red"><b>{ cartError.promo }</b></div>
            :
            <div className="flex-col">Do you have a promo code?</div>
            }
            <div className="flex-stretch min-b-xs"></div>
            <div className="flex-col">
              <input type="text" name="promo_code" value={promoCode || ""} className="form-thin" style={{ width:'124px'}} placeholder="Enter Code" aria-label="Enter Promo Code" onChange={e => onPromoCodeChange(e)}></input>
            </div>
            <div className="flex-col">
              <button className="button button-line" aria-label="Apply Promo Code" style={{height:'32px'}}>Apply</button>
            </div>
          </div>
        </form>
      </section>
      }
      <section className="extra-pad bg">
        <div className="vehicle">
          { oemLogo(vehicle.make_name) &&
          <div className="vehicle-image min-b-xs">
            <div className="vehicle-image-logo">
              <Image src={oemLogo(vehicle.make_name)} alt={vehicle.make_name} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "contain" }} priority={false} sizes={'640'}></Image>
            </div>
          </div>
          }
          <div className="vehicle-trim">
            <div className="vehicle-trim-name">{ vehicle.name }</div>
            { vehicle.vin && vehicle.vin != vehicle.sample_vin &&
            <div className="vehicle-trim-name">VIN: { vehicle.vin }</div>
            }
            { !vehicle.vin && vehicle.sample_vin &&
            <div className="vehicle-trim-name"><a className="link-centered" title="Update Vehicle" onClick={e => updateVehicle(e)}>Enter your vehicle to get an accurate price</a></div>
            }
            <div className="vehicle-trim-miles">
              <div className="vehicle-trim-name">{ formatNumber(vehicle.mileage) } miles</div>
              <div className="vehicle-trim-name">Zip Code: { vehicle.zip_code }</div>
            </div>
          </div>
          <div className="vehicle-miles">
            <div className="vehicle-trim-name">{ formatNumber(vehicle.mileage) } miles</div>
            <div className="vehicle-trim-name">Zip Code: { vehicle.zip_code }</div>
          </div>
        </div>
      </section>
      { !loading &&
      <HelpInfoSection {...props} qr={vehicle.qr}></HelpInfoSection>
      }
      { legal.general &&
      <section>
        <div className="disclaimer" dangerouslySetInnerHTML={{__html: replacePlaceholders(legal.general, { config: props.config }) }}></div>
      </section>
      }
      <Footer {...props}></Footer>

      <Popup ref={customizePopup} className={'product-popup'} title="Product Options">
        <PopupBody>
          <div className="product-options">
            <form id="customize_form" className="form">
            <div className="product-option-filters">
              { selectionOptions?.terms?.length > 0 &&
              <div>
                <label>Term (months)</label>
                <select name="term" value={selectFilters.term || ""} className="form-thin" onChange={e => onSelectFilterChange(e)}>
                  <option value=""></option>
                  { selectionOptions?.terms?.map((opt,optIdx) => (
                    <option key={optIdx} value={opt}>{ formatNumber(opt) } months</option>
                  ))}
                </select>
              </div>
              }
              { selectionOptions?.miles?.length > 0 &&
              <div>
                <label>Miles</label>
                <select name="miles" value={selectFilters.miles || ""} className="form-thin" onChange={e => onSelectFilterChange(e)}>
                  <option value=""></option>
                  { selectionOptions?.miles?.map((opt,optIdx) => (
                    <option key={optIdx} value={opt}>{ formatNumber(opt) } miles</option>
                  ))}
                </select>
              </div>
              }
              { selectionOptions?.deductibles?.length > 0 &&
              <div>
                <label>Deductible</label>
                <select name="deductible" value={selectFilters.deductible || ""} className="form-thin" onChange={e => onSelectFilterChange(e)}>
                  <option value=""></option>
                  <option value="0">None</option>
                  { selectionOptions?.deductibles?.map((opt,optIdx) => (
                    <option key={optIdx} value={opt}>{ formatCurrency(opt) }</option>
                  ))}
                </select>
              </div>
              }
            </div>
            </form>
            { customizeOptions.filter(e => e.plans?.length > 0).map((plan,index) => (
            <div key={index} className={"product-option " + (plan.selected ? "product-option-selected" : "")}>
              <button className="product-info" aria-label={ plan.name + " - Show More" } onClick={e => toggleOption(e, index, -1)}>
                <h3>{ plan.name }</h3>
                { !(selectFilters.term || selectFilters.miles || selectFilters.deductible || selectFilters.deductible == "0") &&
                <div>
                  { formatCurrency(plan.range.min) }{ plan.range.max > plan.range.min ? '-' + formatCurrency(plan.range.max) : '' }
                </div>
                }
                <div className="product-info-stretch"></div>
                <div className="product-info-exp">
                  <span className="button button-icon">{ ARROW  }</span>
                </div>
              </button>
              { productInfo && expandedInfoIndex == index + "--1" &&
                renderProductInfo(plan, productInfo)
              }
              <div className={ "product-config " + (expandedOptionIndex == index || (selectFilters.term || selectFilters.miles || selectFilters.deductible || selectFilters.deductible == "0") ? "product-config-show" : "")}>
                <div className="product-terms">
                { plan.plans.map((p,i) => (
                  <Form key={i} id={"customize_" + p.category} name={"Customize " + p.category + " Form"}className="form product-terms-row">
                    <div className="product-terms-item">
                      <button className="product-terms-item-more" aria-label="More Information" onClick={e => getInfo(e, { group: plan.name, category: p.category, name: p.name }, index, i)}>
                        { COMMENT_INFO }
                      </button>
                      <div className="product-terms-item-text" onClick={e => getInfo(e, { group: plan.name, category: p.category, name: p.name }, index, i)}>
                        { customNames.length > 1 &&
                        <div>{ p.name }</div>
                        }
                        <div>{ summaryText(p, ['term','miles','deduc']) }</div>
                      </div>
                      <div className="product-terms-item-price">
                        <div>{ formatCurrency(p.price) }</div>
                      </div>
                      <div className="product-terms-item-price">
                        <div>
                          { selectedProductId(p.options) ?
                          <button className="button button-green" aria-label="Selected" disabled={true} onClick={e => null}>Selected</button>
                          :
                          <button className="button button-primary" aria-label="Select" onClick={e => onSelect(e,p)}>Select</button>
                          }
                        </div>
                      </div>
                    </div>
                    { p.options?.length > 1 &&
                    <div className="product-terms-options">
                      <div></div>
                      <div>
                        <select name="option_selected" defaultValue={selectedProductId(p.options) || ""} onChange={e => onDeductiblePlanSelect(e, index, i)}>
                          { p.options.map((d,di) => (
                            <option key={di} value={d.product_id}>{ d.option_label } ({ formatCurrency(d.price) })</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    }
                    { productInfo && expandedInfoIndex == (index + "-" + i) &&
                      <div className="product-terms-desc">
                      {renderProductInfo(p, productInfo)}
                      </div>
                    }
                  </Form>
                ))}
                </div>
              </div>
            </div>
            ))}
          </div>
        </PopupBody>
      </Popup>
      <Popup ref={infoPopup} title="Product Information">
        <PopupBody>
          <div className="product-options" style={{height:'auto'}}>
            { bundleProductInfo.map((plan,index) => (
            <div key={index} className="product-option">
              <div className="product-info">
                <h2>{ plan.name }</h2>
                <div className="product-info-stretch"></div>
                <div>
                  <h2>{ formatCurrency(plan.price) }</h2>
                </div>
              </div>
              { renderProductInfo(plan, plan.info) }
            </div>
            ))}
          </div>
        </PopupBody>
      </Popup>
      <Popup ref={surchargesPopup} title="Add Optional Surcharges">
        <PopupBody key={planSurchargesSalt}>
          <div className="flex-row flex-gapped flex-align-middle" style={{ paddingBottom: '16px'}}>
            <div className="flex-stretch">{ planSurcharges?.cat_name }</div>
            <div className="flex-col">{ planSurcharges ? formatCurrency(planPrice(planSurcharges)) : '' }</div>
          </div>
          <Form id="surcharge_form" name="Product Surcharge Form" className="product-options">
            { planSurcharges?.surcharges.map((item,index) => (
            <div key={index} className="product-option">
              <div className="product-info form-toggle">
                <div>
                  <div className="form-toggle-check">
                    { CHECK }
                  </div>
                </div>
                <h3>{ item.name }</h3>
                <div className="product-info-stretch"></div>
                <div>
                  +{ formatCurrency(parseFloat(item.price), 2) }
                </div>
                <input type="checkbox" name="selected" value={item.code || ""} aria-label={"Select Surcharge " + item.name} checked={item.selected} onChange={e => onSurchargeToggle(e)}></input>
              </div>
            </div>
            ))}
          </Form>
        </PopupBody>
        <PopupFooter>
          <button className="button button-primary" aria-label="Continue" onClick={e => onSurchargeDone(e)}>Continue</button>
        </PopupFooter>
      </Popup>
      <Popup ref={vehiclePopup} title="Enter Your Vehicle" className={'popup-vehicle'}>
        <PopupBody>
          <VehicleForm {...props} update={true}></VehicleForm>
        </PopupBody>
      </Popup>
      <Popup ref={videoPanel} className={'video-fs'}>
        { video &&
        <PopupBody>
          <div className="video-fs-container">
            <GlobalVideoPlayer ref={videoPlayer} video={video}></GlobalVideoPlayer>
          </div>
          <button className="video-fs-x" aria-label="Stop" onClick={e => stopVideo(e)}>{ X }</button>
        </PopupBody>
        }
      </Popup>
      <ContactFormPopup ref={contactPopup} cart={cart} linked={true} onSubmit={data => onContactSubmit()}></ContactFormPopup>
    </div>
  </Page>

}