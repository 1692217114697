import Form from "@/components/form/Form";
import Popup from "@/components/popup/Popup";
import PopupBody from "@/components/popup/PopupBody";
import Spinner from "@/components/Spinner";
import ContactForm from "./ContactForm";

const { forwardRef, useRef, useState, useImperativeHandle } = require("react");

const ContactFormPopup = forwardRef((props, ref) => {

  const popup = useRef()

  const show = (e) => {
    popup.current.show()
  }

  const hide = (e) => {
    popup.current.hide()
  }

  const onFormSubmit = (data) => {
    props.onSubmit(data)
    if (!props.linked) hide()
  }

  useImperativeHandle(ref, () => ({
    show, hide
  }))

  return <Popup ref={popup} title="Your information to continue" className="popup-contact">
    <PopupBody>
      <ContactForm {...props} onSubmit={data => onFormSubmit(data)}></ContactForm>
    </PopupBody>
  </Popup>

})

ContactFormPopup.displayName = "ContactFormPopup"
export default ContactFormPopup
