import Form from "@/components/form/Form";
import ApiUtils from "@/utils/ApiUtils";
import { US_STATES } from "@/utils/Constants";
import { isOnlyNumbers, isValidEmail, isValidPhone, isValidZip } from "@/utils/Helpers";

const { forwardRef, useRef, useState, useImperativeHandle } = require("react");

const ContactForm = forwardRef((props, ref) => {

  const cart = props.cart
  const [form, setForm] = useState(Object.assign({}, props.customer || {}))
  const [error, setError] = useState()
  const [processing, setProcessing] = useState()

  const isCustomerValid = (e, formData) => {
    formData = formData || form
    let b = true
    b = b && formData.first_name
    b = b && formData.last_name
    b = b && formData.email && isValidEmail(formData.email)
    b = b && formData.phone && isValidPhone(formData.phone)
    b = b && formData.address_line_1
    b = b && formData.city
    b = b && formData.state
    b = b && formData.zip
    return b || false
  }

  const onFieldChange = (e, value) => {
    value = value || e.target.value
    if (e.target.name == "phone" && value && !isOnlyNumbers(value)) return
    let data = Object.assign({}, form)
    data[e.target.name] = value || e.target.value
    setForm(data)
  }

  const submit = (e) => {
    e.preventDefault()
    setProcessing("customer")
    ApiUtils.postRequest("/v1/carts/" + cart.cart_id + "/customer", form)
      .then(data => {
        if (!props.linked) setProcessing(null)
        props.page?.current.sendMessage("Entered Billing Information")
        props.onSubmit(data)
      })
      .catch(err => {
        setProcessing(null)
      })
  }

  useImperativeHandle(ref, () => ({
  }))

  return <Form className="form" id="checkout" name="Checkout" onSubmit={e => submit(e)}>
    <div className='form-cells'>
      {error &&
        <div className='form-cell text-align-center text-red text-bold'>
          {error}
        </div>
      }
      <div className='form-cell form-cell50a'>
        <p>First Name*</p>
        <input type="text" name="first_name" value={form.first_name || ''} aria-label="First Name" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell form-cell50a'>
        <p>Last Name*</p>
        <input type="text" name="last_name" value={form.last_name || ''} aria-label="Last Name" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell form-cell50'>
        {form.email && !isValidEmail(form.email) ?
          <p className="form-error">Invalid Email</p>
          :
          <p>Email*</p>
        }
        <input type="text" name="email" value={form.email || ''} aria-label="Email" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell form-cell50'>
        {form.phone && !isValidPhone(form.phone) ?
          <p className="form-error">Invalid Phone Number (10-digit)</p>
          :
          <p>Phone Numbe (10-digit)*</p>
        }
        <input type="text" name="phone" value={form.phone || ''} aria-label="Phone" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell'>
        <p>Street Address*</p>
        <input type="text" name="address_line_1" value={form.address_line_1 || ''} aria-label="Street Address" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell'>
        <p>Apartment, Suite or Unit</p>
        <input type="text" name="address_line_2" value={form.address_line_2 || ''} aria-label="Apartment, Suite or Unit" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell form-cell50a'>
        <p>City*</p>
        <input type="text" name="city" value={form.city || ''} aria-label="City" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell form-cell50a'>
        <p>State*</p>
        <select name="state" value={form.state || ''} aria-label="State" onChange={e => onFieldChange(e)}>
          <option value="">State</option>
          {US_STATES.filter(e => e).map((s, i) => (
            <option key={s} value={s}>{s}</option>
          ))}
        </select>
      </div>
      <div className='form-cell form-cell50a'>
        {form.zip && !isValidZip(form.zip) ?
          <p className="form-error">Invalid Zip (5-digit)</p>
          :
          <p>Zip / Postal Code* (5-digit)</p>
        }
        <input type="text" name="zip" value={form.zip || ''} aria-label="Zip Code" placeholder="Zip Code" onChange={e => onFieldChange(e)}></input>
      </div>
      <div className='form-cell form-cell50a'>
        <button type="submit" className={'button button-primary ' + (processing == "customer" ? 'processing' : '')} disabled={!isCustomerValid()}>
          <span>{'Continue'}</span>
          {processing == "customer" &&
            <div className={'processing-ring'}><div></div><div></div><div></div><div></div></div>
          }
        </button>
      </div>
    </div>
  </Form>


})

ContactForm.displayName = "ContactForm"
export default ContactForm
