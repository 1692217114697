import Popup from "@/components/popup/Popup"
import PopupBody from "@/components/popup/PopupBody"
import { useRef, useState } from "react"
import GlobalVideoPlayer from "./GlobalVideoPlayer"
import { PLAY, QUOTE, X } from "@/components/Icons"
import Image from "next/image"
import Form from "@/components/form/Form"

export default function HelpInfoSection(props) {

  const prodConfig = props.config.products

  const videoPanel = useRef()
  const videoPlayer = useRef()
  
  const [video,setVideo] = useState()

  const playVideo = (e, video) => {
    e.preventDefault()
    setVideo(video)
    videoPanel.current.show()
    if (videoPlayer?.current) {
      videoPlayer.current.play(video.yt_link)
    }
  }

  const stopVideo = (e) => {
    videoPlayer.current.stop()
    videoPanel.current.hide()
  }

  if (!prodConfig.info?.comment && !prodConfig.info?.video?.video_source) {
    return <></>
  }
  
  return <>
    <section></section>
    <section>
      <div className="product-items">
        { prodConfig.info.comment &&
        <div className="product-items-comment">
          { QUOTE }
          <div>
            <span>{ prodConfig.info.comment }</span>
            { prodConfig.info.comment_by &&
            <span className="product-items-comment-by"><i>- { prodConfig.info.comment_by }</i></span>
            }
          </div>
        </div>
        }
        <div style={{width:0}}>
        </div>
        { prodConfig.info.video?.cover &&
        <div>
          <Form id="helpvideo_play" button={true} name="Play Help Video" className="flex-column flex-gapped" onSubmit={e => playVideo(e, prodConfig.info.video)}>
            <span>{ prodConfig.info.video_header }</span>
            <button type="submit" className="product-items-video">
              <Image src={prodConfig.info.video?.cover} alt={prodConfig.info.video_header} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "cover" }} priority={false} sizes={'1600x1600'}></Image>
              <div className="product-items-video-play">{ PLAY }</div>
            </button>
          </Form>
        </div>
        }
        { props.qr &&
        <>
          <div style={{width:0}} className="min-b-xs"></div>
          <div className="qr-code min-b-xs">
            <div className="flex-column flex-gapped ">
              <span>{ 'Scan & switch to mobile' }</span>
              <div dangerouslySetInnerHTML={{ __html: props.qr }}></div>
            </div>
          </div>
        </>
        }
      </div>
    </section>
    <Popup ref={videoPanel} className={'video-fs'}>
      { video &&
      <PopupBody>
        <div className="video-fs-container">
          <GlobalVideoPlayer ref={videoPlayer} video={video}></GlobalVideoPlayer>
        </div>
        <button className="video-fs-x" onClick={e => stopVideo(e)}>{ X }</button>
      </PopupBody>
      }
    </Popup>
  </>

}